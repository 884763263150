<template>
  <div class="article">
    <div class="article_bg"></div>

    <MerchantsHeader></MerchantsHeader>

    <div class="back_btn">
      <div class="btn" @click="$router.back(-1)">
        <i class="el-icon-arrow-left"></i>
        返回
      </div>
    </div>

    <div class="info">
      <div class="info_left">
        <div class="info_left_1">
          <img
            class="info_img"
            :src="articleInfo.StoreHeadImage || defaultPic"
            alt=""
            srcset=""
            @click="goMerchants(articleInfo.UserId)"
          />

          <div class="info_details">
            <div class="info_name" @click="goMerchants(articleInfo.UserId)">
              <span>{{ articleInfo.StoreName || '--' }}</span>
              <img
                v-if="articleInfo.UserId == 44"
                class="info_icon"
                src="../../assets/icon/official.png"
                alt=""
              />
              <img
                v-else-if="articleInfo.CertificationType"
                class="info_icon2"
                src="../../assets/icon/certification.png"
                alt=""
              />
              <img
                v-else
                class="info_icon2"
                src="../../assets/icon/no_certification.png"
                alt=""
              />
            </div>

            <!-- <div class="info_text">
            资讯共享，每日更新报价、同行交流平台、致力于成为线上线下一体化的服务商！
          </div> -->

            <div class="info_date">
              <span>{{ new Date(articleInfo.UpdateTime) | formatTime }}</span>
              <span>丨</span>
              <span
                >发布于{{ articleInfo.City || ''
                }}{{ articleInfo.Area || '' }}</span
              >
            </div>
          </div>
        </div>

        <div class="talk">
          <div class="to_he" @click="codeShow = !codeShow">找Ta聊聊</div>
          <div class="code_show" v-show="codeShow">
            <i class="el-icon-close code_close" @click="codeShow = false"></i>
            <img
              class="code_img"
              src="../../assets/img/code3.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>

      <div class="focus">
        <div>
          <div class="focus_num">
            <span class="num">{{ merchantsCount.support }}</span>
            <span> 留言</span>
            <span class="num">{{ merchantsCount.fans }}</span>
            <span> 粉丝</span>
            <span class="num">{{ merchantsCount.follow }}</span>
            <span> 关注</span>
          </div>
          <div
            :class="focusFlag ? 'focus_on' : 'focus_btn'"
            @click="$globalFun.throttle(addFocus, 1000, true)"
            v-if="userInfo.Id !== articleInfo.Id"
          >
            {{ focusFlag ? '已关注' : '关注' }}
          </div>
        </div>
      </div>
    </div>

    <div class="article_details">
      <div class="details_left">
        <div class="details_text">
          {{ articleInfo.Title || '' }}
        </div>

        <div class="details_img" v-if="articleInfo.image">
          <img
            class="item_imgs"
            v-for="item in articleInfo.image"
            :key="item.Id"
            :src="item.Image"
            alt=""
            srcset=""
          />
        </div>
      </div>

      <div class="details_right">
        <div class="details_right_title">Ta的热点</div>

        <MerchantsReleaseHot
          :dataList="merchantsArticleList.slice(0, 10)"
          @goDetails="goDetails"
        ></MerchantsReleaseHot>
      </div>
    </div>

    <!-- <div class="talk">
      <div class="to_he" @click="codeShow = !codeShow">找Ta聊聊</div>
      <img
        v-show="codeShow"
        class="code_img"
        src="../../assets/img/code3.png"
        alt=""
        srcset=""
      />
    </div> -->

    <AllFooter></AllFooter>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultPic: 'https://api.chajiuqu.com/uploadImage/img/defaultPict.png',
      articleInfo: {},

      userInfo: JSON.parse(localStorage.getItem('info') || null),

      merchantsCount: {},

      focusFlag: false,

      codeShow: false,

      merchantsArticleList: [],
    }
  },

  created() {
    const isLogin = this.$store.state.tokens
    if (!isLogin) {
      this.$router.push({
        path: '/login',
      })
    }

    document.documentElement.scrollTop = 0

    this.getArticleInfo(this.$route.query.id)
  },

  methods: {
    async getArticleInfo(id) {
      const { data } = await this.$api.user.getArticleInfo({
        id,
      })
      console.log('文章详情', data)
      this.articleInfo = data

      this.articleInfo.image.forEach((item) => {
        if (item.Image.indexOf('cosgz.myqcloud.com') > -1) {
          item.Image =
            item.Image.replace('cosgz', 'cos.ap-guangzhou') + '/webp_img'
        }
      })

      this.getStoreCount(this.articleInfo.UserId)
      this.getCollect(this.articleInfo.UserId)
      this.getArticleList(this.articleInfo.UserId)
    },

    async getArticleList(userId) {
      const { data } = await this.$api.user.articleListUser({
        pageindex: 1,
        userId,
      })
      console.log('用户发布文章', data)
      this.merchantsArticleList = data.list.slice(0, 10)
    },

    // 个人首页
    goMerchants(id) {
      this.$router.push({
        path: '/merchants/index',
        query: {
          id,
        },
      })
    },

    goDetails(id) {
      if (this.articleInfo.Id == id) return
      this.$router.push({
        path: '/merchants/details',
        query: {
          id,
        },
      })

      document.documentElement.scrollTop = 0

      this.getArticleInfo(id)
    },

    // 获取粉丝关注数目
    async getStoreCount(userId) {
      const { data } = await this.$api.user.getStoreCount({
        userId,
      })
      console.log('获取粉丝数', data)
      this.merchantsCount = data
    },

    // 获取关注商家
    async getCollect(userId) {
      const { data: res } = await this.$api.user.getCollect({
        userId,
      })
      console.log('获取关注商家', res)
      if (res) this.focusFlag = true
    },

    // 添加/删除关注
    addFocus() {
      if (!this.focusFlag) {
        this.addCollect()
      } else {
        this.delCollect()
      }
    },

    // 添加关注
    async addCollect() {
      const { data: res } = await this.$api.user.addCollect({
        userId: this.$route.query.id,
      })
      console.log('添加关注', res)
      this.$message({
        message: '关注成功',
        type: 'success',
      })
      this.focusFlag = true
      console.log(this.focusFlag)
    },
    // 删除关注
    async delCollect() {
      const { data: res } = await this.$api.user.delCollect({
        userId: this.$route.query.id,
      })
      console.log('删除关注', res)
      this.$message({
        message: '删除关注成功',
        type: 'warning',
      })
      this.focusFlag = false
      console.log(this.focusFlag)
    },
  },
}
</script>

<style lang="scss" scoped>
.article {
  width: 100%;
  position: relative;

  .article_bg {
    width: 100%;
    height: 410px;
    background: url('../../assets/img/merchantsBg.png') no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .back_btn {
    width: 1126px;
    height: 55px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .btn {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
      cursor: pointer;
    }
  }

  .info {
    width: 1126px;
    margin: 0 auto;
    display: flex;

    .info_left {
      flex: 1;
      margin-right: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
    }

    .info_left_1 {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .info_img {
      width: 84px;
      height: 84px;
      display: block;
      border-radius: 50%;
      cursor: pointer;
    }

    .info_details {
      flex: 1;
      margin-left: 12px;
      text-align: left;

      .info_name {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #3d3d3d;
        display: flex;
        align-items: center;
        cursor: pointer;

        .info_icon {
          width: 91px;
          height: 20px;
          display: block;
          margin-left: 10px;
        }

        .info_icon2 {
          width: 83px;
          height: 21px;
          display: block;
          margin-left: 10px;
        }
      }

      .info_text {
        margin-top: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #5f5f5f;
      }

      .info_date {
        margin-top: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #5f5f5f;
      }
    }

    .talk {
      width: 147px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .to_he {
        width: 147px;
        height: 42px;
        background: linear-gradient(180deg, #ff4536 0%, #dd3b2e 100%);
        box-shadow: 0px 5px 10px rgba(255, 118, 118, 0.35);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
      }

      .code_show {
        width: 300px;
        height: 355px;
        background-color: #fff;
        position: absolute;
        top: 42px;
        right: 0;
        z-index: 990;

        .code_close {
          font-size: 24px;
          color: #000000;
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 999;
          cursor: pointer;
        }

        .code_img {
          width: 300px;
          display: block;
        }
      }
    }

    .focus {
      width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;

      .focus_num {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #797979;

        .num {
          font-size: 24px;
          font-weight: 600;
          line-height: 33px;
          color: #3d3d3d;
          margin-left: 20px;
        }

        .num:first-child {
          margin-left: 0;
        }
      }

      .focus_btn {
        width: 173px;
        height: 39px;
        margin: 12px auto 0;
        line-height: 39px;
        text-align: center;
        border: 1px solid #d83e26;
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #d83e26;
        cursor: pointer;
      }

      .focus_on {
        width: 173px;
        height: 39px;
        margin: 12px auto 0;
        line-height: 39px;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;
        border: 1px solid #d6d6d6;
      }
    }
  }

  .article_details {
    width: 1126px;
    margin: 20px auto 0;
    display: flex;

    .details_left {
      flex: 1;
      text-align: left;

      .details_text {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 36px;
        color: #5c5c5c;
        white-space: pre-line;
      }

      .details_img {
        margin-top: 15px;

        .item_imgs {
          width: 100%;
          margin-top: 5px;
          display: block;
        }
      }
    }

    .details_right {
      margin-left: 56px;
      width: 320px;

      .details_right_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 25px;
        color: #222222;
      }
    }
  }
}
</style>
